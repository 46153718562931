import React, { useState } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout/layout"
import Meta from "../../components/addon/meta"
import Schema from "../../components/addon/schema"
import BtnLink from "../../components/addon/Link"

import useDeviceDetect from "../../service/useDeviceDetect"

const Careers = ({ data: { allNodeTemplatePages: { edges } } }) => {
    
    let { isMobile } = useDeviceDetect();
    let metaTags = []
    let schema = []
    let canonicalUrl = {}
    let careersData = {}
    let title = ""
    let bannerImage = ""
    let field_title = ""
    let field_description = ""
    let boxTitle1 = ""
    let boxButtonText = ""
    let boxButtonLink = ""
    let boxTitle2 = ""
    let boxDescription2 = ""
    let boxButtonLink2 = ""
    let boxButtonText2 = ""
    let boxTitle3 = ""
    let boxDescription3 = ""
    let boxButtonText3 = ""
    let boxButtonLink3 = ""
    let bigBoxDescription = ""

    if (edges.length > 0) {
        metaTags = edges[0]?.node?.metatag_normalized ? edges[0]?.node?.metatag_normalized : [];
        schema = edges[0]?.node?.relationships?.field_seo_schema ? edges[0]?.node?.relationships?.field_seo_schema : []
        canonicalUrl = edges[0]?.node?.metatags_on_page || {}
        careersData = edges[0]?.node?.relationships;
        title = careersData?.components[0]?.title || "";
        bannerImage = careersData?.components[0]?.relationships?.bannerImage?.uri?.url?.replace('http://', 'https://');
        field_title = careersData?.components[1]?.field_title || "";
        field_description = careersData?.components[1]?.text?.processed || ""
        boxTitle1 = careersData?.components[2]?.field_title;
        boxButtonText = careersData?.components[2]?.field_cta_link?.title || "";
        boxButtonLink = careersData?.components[2]?.field_cta_link?.uri;
        boxTitle2 = careersData?.components[3]?.field_title || "";
        boxDescription2 = careersData?.components[3]?.text?.processed || "";
        boxButtonText2 = careersData?.components[3]?.field_cta_link?.title || "";
        boxButtonLink2 = careersData?.components[3]?.field_cta_link?.uri;
        boxTitle3 = careersData?.components[4]?.field_title || "";
        boxDescription3 = careersData?.components[4]?.text?.processed || "";
        boxButtonText3 = careersData?.components[4]?.field_cta_link?.title || "";
        boxButtonLink3 = careersData?.components[4]?.field_cta_link?.uri;
        bigBoxDescription = careersData?.components[5]?.htmlText?.processed || "";
    }

    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/services.css", "/assets/css/accessibility.css"],
                    }}
                    tags={{metaTags,canonicalUrl}}
                />
                <Schema schema={schema} />
                <main className="innerpage pt-first-section">
                    <section className="inner_common_banner">
                        <picture className="banner_img">
                            <img src={bannerImage} alt="bannerIamge carreers" />
                        </picture>
                        <div className="banner_content text_left text_dark">
                            <div className="container">
                                <h1>{title ? title : "Careers"}</h1>
                            </div>
                        </div>
                    </section>
                    <section className="section_healthcheckup section-py">
                        <div className="container">
                            <div className="heading-section text-center">
                                <h3>{field_title ? field_title : "Being a part of 'Hinduja Parivar'"}</h3>
                                <div dangerouslySetInnerHTML={{ __html: field_description ? field_description : "" }} />
                            </div>
                            <div className="checkup_slider no-slider career_boxes">
                                <div className="row">
                                    <div className="col-md-4 slide-col">
                                        <div className="slide">
                                            <div className="checkup_card career_card">
                                                <h3 className="name">{boxTitle1 ? boxTitle1 : "To view current openings and apply"}</h3>
                                                <div className="btn_wrap">
                                                    <Link to={"/careers/listing/"}>
                                                        <span className="btn btn-primary-solid">{boxButtonText ? boxButtonText : "View More"}</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 slide-col">
                                        <div className="slide">
                                            <div className="checkup_card career_card">
                                                <h3 className="name">{boxTitle2 ? boxTitle2 : "Looking to join our Parivar"}</h3>
                                                <div dangerouslySetInnerHTML={{ __html: boxDescription2 }} />
                                                <div className="btn_wrap">
                                                    <BtnLink classes="btn btn-primary-solid" data={{
                                                        link : {
                                                            uri : '/careers/postings',
                                                            title : "Submit Your CV"
                                                        }
                                                    }}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 slide-col">
                                        <div className="slide">
                                            <div className="checkup_card career_card">
                                                <h3 className="name">{boxTitle3 ? boxTitle3 : "Want to know what life at Hinduja Hospital is like?"}</h3>
                                                <div dangerouslySetInnerHTML={{ __html: boxDescription3 }} />
                                                <div className="btn_wrap">
                                                    <Link to={boxButtonLink3 ? boxButtonLink3 : "/"} target="_blank">
                                                        <span className="btn btn-primary-solid">{boxButtonText3 ? boxButtonText3 : "CLICK HERE TO DOWNLOAD"}</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div className="content_card_box mt-4">
                                <div className="row" dangerouslySetInnerHTML={{ __html: bigBoxDescription ? bigBoxDescription : "" }} />
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}
export const query = graphql`
query Careers {
allNodeTemplatePages(filter: {path: {alias: {regex: "/careers$/"}}}) {
edges {
node {
id
title
path {
alias
}
relationships{
    field_seo_schema{
        field_seo_title
        field_seo_schema_text_area
    }
}
metatag_normalized {
attributes {
content
name
property
}
}
metatags_on_page: field_meta_tags{
    canonical_url
  }
relationships {
components: field_component_type {
__typename
...ParagraphBanner
...ParagraphTitleAndDescription
...ParagraphTitleAndDescription
... on paragraph__title_and_description {
id
field_cta_link {
title
uri
}
}
...ParagraphTitleAndDescription
... on paragraph__title_and_description {
id
field_cta_link {
title
uri
}
}
...ParagraphTitleAndDescription
... on paragraph__title_and_description {
id
field_cta_link {
title
uri
}
}
...ParagraphHTMLText
}
}
}
}
}
}`
export default Careers